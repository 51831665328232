/**
 * ==================== @ ICONS
 */

.svg-icon {
	display: inline-block;
	fill: currentColor;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	vertical-align: -0.2em; // https://fvsch.com/code/svg-icons/how-to/#section-styling
	width: 1em;
}

.icon-rs {
	background-color: rgba($color13, .2);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	width: 32px;

	.svg-icon {
		transition: color 150ms;
	}

	&:hover {

		.svg-icon {
			color: $color11;
		}
	}
}

@media screen and (min-width: $bp-l) {
}