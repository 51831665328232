/**
 * ==================== @ GLOBAL LAYOUT
 */

/*
	- fluid margin - 						body content 12 cols  			- fluid margin
	1				2												8												-2			-1
	|				|		|		|		|		|		|		|		|		|		|		|		|		|				|

	Nomenclature : 
	grid item default is 3 / -3 desktop
	grid item pass --gdspan as var if no preset
*/

.grid-row {
	--gdspan: 2/-2;
	display: grid;
	grid-template-columns: 1rem repeat(4, minmax(1rem, 1fr)) 1rem;

	.grid-data {
		grid-column: 2/-2;
		
		&[data-cols="full-bleed"] {
			grid-column: 1/-1;
		}
		// Preset 8 cols
		&[data-cols="boxed-8"],
		&[data-cols="boxed-10"] {
			grid-column: 2/-2;
		}
	}
}

/* --- 1024 --- */
@media screen and (min-width:$bp-l) {
	 
	.grid-row {
		grid-template-columns: minmax(1rem, 1fr) repeat(12, minmax(1rem, var(--col-width))) minmax(1rem, 1fr);
		// Default body - 12 cols
		--gdspan: 2/-2;
		.grid-data {
			grid-column: var(--gdspan);
			
			// Preset 10 cols -  Most used by edito
			&[data-cols="boxed-10"] {
				grid-column: 3/-3;
			}
			
			// Preset full width
			&[data-cols="full-bleed"] {
				grid-column: 1/-1;
			}
		}

    &.vcenter {
      align-items: center;
    }
	}
}