/**
 * ==================== @ BLOCKS SECTION
 */

section  {
  padding-top: 2rem;
  position: relative;

  &.bg-rose {
    color: #fff;
    background-color: $color3;
  }

  &.bg-saumon {
    color: #fff;
    background-color: $color5;
  }

  &.layout {

    &-txt {

      .home & {
        padding-top: 6rem;
        padding-bottom: 8rem;

        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 2;
        }

        &::before {
          background: url(/assets/images/layout/petal-top.webp) no-repeat top right;
          background-size: contain;
          top: 0;
          right: 0;
          width: 280px;
          height: 150px;
        }

        &::after {
          background: url(/assets/images/layout/petal-bottom.webp) no-repeat bottom left;
          background-size: contain;
          bottom: 0;
          left: 0;
          width: 280px;
          height: 150px;
        }
      }
    }
    
    &-2cols, &-img-txt, &-txt-img {
  
      .data-first {
        padding-right: 2rem;
      }
  
      .data-last {
        padding-left: 2rem;
      }

      figure {
        padding: 0 2rem;
      }
    }

  }
}

/* === 1024px === */
@media screen and (min-width:$bp-l) {

  section {

    &.layout {

      &-txt {
  
        .home & {
  
          &::before {
            width: 400px;
            height: 215px;
          }
  
          &::after {
            width: 400px;
            height: 215px;
          }
        }
      }
    
      &-2cols, &-img-txt, &-txt-img {
    
        .data-first {
          padding-right: 2rem;
        }
    
        .data-last {
          padding-left: 2rem;
        }
      }
    }
  }
}
