/**
 * ==================== @ BUTTONS
 */

.btn-wrapper {
	padding: 1rem 0;

	&.m--center {
		text-align: center;
	}
	
	&.m--right {
		text-align: right;
	}
}

.btn {
	background-color: $color2;
	border-radius: 6px;
	color: #fff;
	font-size: rem(16);
	padding: 6px 16px;
	text-decoration: none;
}