@charset "UTF-8";
/**
 * Use BEM : https://en.bem.info/
 */
/**
 * ==================== @ THEME VARIABLES
 */
:root {
  --gutter: 1rem;
  --margin-layout: 1rem;
  --col-width: 85px;
  --radius-6: 3px;
  --radius-8: 4px;
  --radius-16: 8px;
  --header-height: 64px;
  --theme-color: #bc6b3d;
  --theme-color-alt: #bc6b3d;
}

@media screen and (min-width: 64em) {
  :root {
    --radius-6: 6px;
    --radius-8: 8px;
    --radius-16: 1rem;
    --header-height: 84px;
  }
}
/*
$svg-pen: '<svg viewBox="0 0 38 32"><path d="M1.69 1.7A5.61 5.61 0 0 1 5.07.04h.02a4.03 4.03 0 0 1 3.33 1.11l21 21c.1.1.17.22.2.34l2.27 8.53a.75.75 0 0 1-.72.97.74.74 0 0 1-.2 0l-8.52-2.3a.68.68 0 0 1-.34-.2L1.14 8.43a4.8 4.8 0 0 1 .54-6.73zm25.13 24.07a5.69 5.69 0 0 0 1.27-2.76v-.04L8.34 3.22A7.8 7.8 0 0 1 6.9 5.85v-.01zm2-.32a8.52 8.52 0 0 1-3.38 3.4l-.05.03 4.7 1.27zm-5.9 2.67a5.66 5.66 0 0 0 2.81-1.28L5.81 6.94a7.76 7.76 0 0 1-2.56 1.43l-.06.02zM2 7.1a6.58 6.58 0 0 0 5.07-5.04L7.08 2a2.6 2.6 0 0 0-1.86-.47h.01c-.98.11-1.84.56-2.48 1.23A3.41 3.41 0 0 0 2 7.1z"></path></svg>';
*/
/**
 * ==================== @ SASS FUNCTIONS
 */
/**
 * ==================== @ SASS MIXINS
 */
/**
 * ==================== @ SASS PLACEHOLDERS
 */
.l-clearfix::before, .l-clearfix::after {
  content: " ";
  display: table;
}
.l-clearfix::after {
  clear: both;
}

/**
 * ==================== @ BASE STYLES
 */
@font-face {
  font-family: "OpenSans";
  src: url("/assets/fonts/OpenSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src: url("/assets/fonts/OpenSans-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src: url("/assets/fonts/OpenSans-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src: url("/assets/fonts/OpenSans-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src: url("/assets/fonts/OpenSans-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
html {
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #fff;
  color: #567199;
  font: 1em "OpenSans", Helvetica, Arial, sans-serif;
  letter-spacing: 0.5px;
  padding: 0;
  margin: 0;
  position: relative;
}
body.is-nav-open {
  height: 100vh;
  overflow: hidden;
}

a:not(.btn) {
  color: #567199;
  text-decoration: none;
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

strong {
  font-size: inherit;
  font-weight: 700;
  margin-left: 0;
}

figure {
  display: block;
  margin: 0;
  overflow: hidden;
  position: relative;
}
figure img {
  display: block;
  width: 100%;
}
figure picture {
  display: block;
}
figure figcaption {
  font-size: 0.75em;
}

iframe {
  border: 0;
}

img {
  height: auto;
  max-width: 100%;
  display: inline-block;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
textarea,
select {
  border-radius: 0;
}

.sv-g [class*=sv-u] {
  font-family: "OpenSans", Helvetica, Arial, sans-serif;
  letter-spacing: 0.1px;
}

@media screen and (min-width: 64em) {
  body.is-nav-open {
    height: auto;
    overflow: visible;
  }
  body.is-nav-open::before {
    content: none;
  }
}
/**
 * ==================== @ GENERIC FLOW TOOLS
 */
.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.l-untitle {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.l-center {
  text-align: center;
}

.l-right {
  text-align: right;
}

.py-0 {
  padding: 0 !important;
}

.py-2 {
  padding: 2rem 0;
}

.py-2-0 {
  padding-top: 2rem;
}

.py-3-0 {
  padding-top: 3rem;
}

.py-4-0 {
  padding-top: 4rem;
}

.my-2-0 {
  margin-top: 2rem;
}

.my-3-0 {
  margin-top: 3rem;
}

.my-6-0 {
  margin-top: 6rem;
}

.my-9-0 {
  margin-top: 9rem;
}

.my-0-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.img-block {
  display: block;
}

.divider {
  /*url("data:image/svg+xml,%3Csvg id='comp-lqpo28l6-bottom' preserveAspectRatio='none' data-bbox='0 91 1920 209' viewBox='0 91 1920 209' height='100%25' width='100%25' xmlns='http://www.w3.org/2000/svg' data-type='shape'%3E%3Cdefs%3E%3Cstyle%3E%23comp-lqpo28l6-bottom %3E g %7B transform: scaleY(-1) translateY(-391px); %7D %23comp-lqpo28l6-bottom %7B fill: %23FFFFFF; %7D%3C/style%3E%3C/defs%3E%3Cg%3E%3Cpath fill-rule='evenodd' d='M1920 291C1656.8 169.2 1323.3 91 960 91S262.5 169.2 0 291v9h1920v-9z M0,90 h1920 v210 h-1920 Z'/%3E%3C/g%3E%3C/svg%3E");*/
}

/* EMBED
----------------------------------*/
.l-embed {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16.9 */
  height: 0;
  overflow: hidden;
}

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page {
  min-height: 400px;
}

.l-main {
  background-color: #fff;
  position: relative;
}

#contact-form {
  scroll-margin-top: 150px;
}

@media screen and (max-width: 767px) {
  .l--desktop {
    display: none;
  }
}
@media screen and (min-width: 64em) {
  .l--mobile {
    display: none;
  }
  .padded-edges {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
/**
 * ==================== @ ICONS
 */
.svg-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  vertical-align: -0.2em;
  width: 1em;
}

.icon-rs {
  background-color: rgba(199, 185, 157, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
}
.icon-rs .svg-icon {
  transition: color 150ms;
}
.icon-rs:hover .svg-icon {
  color: #4C94B2;
}

/**
 * ==================== @ GLOBAL LAYOUT
 */
/*
	- fluid margin - 						body content 12 cols  			- fluid margin
	1				2												8												-2			-1
	|				|		|		|		|		|		|		|		|		|		|		|		|		|				|

	Nomenclature : 
	grid item default is 3 / -3 desktop
	grid item pass --gdspan as var if no preset
*/
.grid-row {
  --gdspan: 2/-2;
  display: grid;
  grid-template-columns: 1rem repeat(4, minmax(1rem, 1fr)) 1rem;
}
.grid-row .grid-data {
  grid-column: 2/-2;
}
.grid-row .grid-data[data-cols=full-bleed] {
  grid-column: 1/-1;
}
.grid-row .grid-data[data-cols=boxed-8], .grid-row .grid-data[data-cols=boxed-10] {
  grid-column: 2/-2;
}

/* --- 1024 --- */
@media screen and (min-width: 64em) {
  .grid-row {
    grid-template-columns: minmax(1rem, 1fr) repeat(12, minmax(1rem, var(--col-width))) minmax(1rem, 1fr);
    --gdspan: 2/-2;
  }
  .grid-row .grid-data {
    grid-column: var(--gdspan);
  }
  .grid-row .grid-data[data-cols=boxed-10] {
    grid-column: 3/-3;
  }
  .grid-row .grid-data[data-cols=full-bleed] {
    grid-column: 1/-1;
  }
  .grid-row.vcenter {
    align-items: center;
  }
}
/**
 * ==================== @ THEMES
*/
.them-therapie {
  --theme-color: #F33493;
  --theme-color-alt: #df2970;
}

.them-coaching {
  --theme-color: #AE0707;
  --theme-color-alt: #af0707;
}

.them-usui {
  --theme-color: #F56F8E;
  --theme-color-alt: #cd5c60;
}

.them-kuna {
  --theme-color: #F7A745;
  --theme-color-alt: #ffa428;
}

.them-about {
  --theme-color: #bc6b3d;
  --theme-color-alt: #bc6b3d;
}

h1, h2, h3, h4, h5 {
  line-height: 1.1;
  margin: 0.5rem 0 1rem 0;
}

h1 {
  color: #fff;
  font-size: 1.875rem;
  line-height: 1.1;
  margin: 0.5rem 0;
}
h1.m--404 {
  font-size: 2.75rem;
}

h2 {
  font-size: 1.5625rem;
  font-weight: 600;
  line-height: 1.2;
  position: relative;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.3125rem;
  margin: 1.5rem 0;
}

h4 {
  font-size: 1rem;
}

.entry, .entry p, p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0.5rem 0 1rem 0;
}
.entry a:not(.btn), .entry p a:not(.btn), p a:not(.btn) {
  font-size: 1rem;
  text-decoration: underline;
}
.entry a:not(.btn):hover, .entry p a:not(.btn):hover, p a:not(.btn):hover {
  text-decoration: underline;
}
.entry code, .entry p code, p code {
  color: var(--theme-color, #F56F8E);
  font-family: "OpenSans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.2;
  text-decoration: none;
}

.suptitle {
  color: #F7A745;
  font-weight: 500;
  font-size: 2.25rem;
  margin: 1rem 0 3rem 0;
}

.osmelis {
  padding-bottom: 2rem;
  text-align: center;
}
.osmelis h1, .osmelis h2, .osmelis h3 {
  color: #F56F8E;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: #ffffff 3px 3px 0px, rgba(0, 0, 0, 0.2) 6px 6px 0px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.osmelis h1 {
  font-size: 4rem;
  margin-bottom: 0.75rem;
}
.osmelis h2 {
  font-size: 2.5rem;
}
.osmelis h3 {
  font-size: 2.5rem;
  text-shadow: #fff 2px 2px 0, rgba(0, 0, 0, 0.2) 4px 4px 0;
  margin: 0.5rem 0;
}
.osmelis small {
  color: #fff;
  display: block;
  font-size: 1.5rem;
}
.home .osmelis small {
  font-size: 1.25rem;
}

.author {
  font-size: 2.5rem;
  font-weight: 700;
}

a[href^="mailto:"] {
  word-break: break-all;
}

.grid-item ul {
  margin-left: 0;
  padding-left: 1rem;
}

.table-cookies {
  margin: 1rem 0;
}
.table-cookies td, .table-cookies th {
  border-bottom: 1px solid #eee;
  font-size: 0.9375rem;
  padding: 6px;
  text-align: left;
}
.table-cookies th {
  font-weight: 500;
}

@media all and (min-width: 48em) {
  h1 {
    font-size: 6rem;
  }
  h2 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  h3 {
    font-size: 1.875rem;
  }
  .osmelis {
    text-align: right;
    margin-right: 2rem;
  }
  .home .osmelis {
    text-align: center;
  }
  .osmelis h1 {
    font-size: 7rem;
    margin-bottom: 1rem;
  }
  .osmelis h2 {
    font-size: 4rem;
  }
  .home .osmelis small {
    font-size: 2.25rem;
  }
  .entry, .entry p, p {
    font-size: 1.125rem;
  }
  .entry code, .entry p code, p code {
    font-size: 1.625rem;
  }
  .entry h2 code, .entry p h2 code, p h2 code {
    font-size: 2.0625rem;
  }
  .suptitle {
    font-size: 4rem;
  }
  .author {
    font-size: 5rem;
  }
}
/**
 * ==================== @ BUTTONS
 */
.btn-wrapper {
  padding: 1rem 0;
}
.btn-wrapper.m--center {
  text-align: center;
}
.btn-wrapper.m--right {
  text-align: right;
}

.btn {
  background-color: #48aecb;
  border-radius: 6px;
  color: #fff;
  font-size: 1rem;
  padding: 6px 16px;
  text-decoration: none;
}

/**
 * ==================== @ PAGINATION - NEWS
*/
.pagination {
  margin: 2rem 0;
  width: 100%;
}
.pagination .pagination-list {
  display: flex;
  width: max-content;
  margin: 0 auto;
}
.pagination .pagination-list .page-items {
  margin: 0 0.3rem;
  text-align: center;
}
.pagination .pagination-list .page-items a {
  border-radius: 50%;
  color: #567199;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  transition: border-color 150ms;
}
.pagination .pagination-list .page-items .svg-icon {
  vertical-align: -2px;
}
.pagination .pagination-list .page-items.m--active a, .pagination .pagination-list .page-items:hover a {
  border: 1px solid #567199;
}

/**
 * ==================== @ GENERIC FORMS
*/
.form {
  font-family: "OpenSans", Helvetica, Arial, sans-serif;
}
.form__field {
  display: flex;
  position: relative;
  padding-bottom: 0.5rem;
}
.form__label {
  display: inline-block;
  font-size: 1.0625rem;
  font-weight: 300;
  padding-left: 16px;
  padding-bottom: 4px;
}
.form__label.m--mandatory span {
  cursor: pointer;
  font-size: 0.6875rem;
  font-weight: 300;
  line-height: 0.9;
}
.form__input, .form__text {
  border: 0;
  border-radius: 24px;
  color: #000;
  outline: 0;
  padding: 7px 16px;
  background-color: rgba(228, 137, 144, 0.1);
  font-size: 1.0625em;
  font-family: inherit;
  font-weight: 300;
}
.m--submit-inline .form__input, .m--submit-inline .form__text {
  padding-right: 25%;
  width: 100%;
}
.form__text {
  min-height: 120px;
  padding: 8px 16px;
  width: 100%;
}
.form__button {
  font-family: inherit;
  font-size: 0.9375em;
  font-weight: 500;
}
.m--submit-inline .form__button {
  position: absolute;
  right: 0;
  z-index: 2;
}
.form .f_error {
  color: red;
  padding-left: 1rem;
}
.form .f_alert {
  font-size: 1.125rem;
  text-align: center;
  margin: 4rem 0;
}
.form .f_alert.success {
  color: green;
  padding: 1rem;
}
.form .f_alert.success::before {
  content: "✓";
  height: 14px;
  width: 14px;
  margin-right: 0.5rem;
  display: inline-block;
}

/**
 * ==================== @ HEADER
 */
.header {
  background-color: #fff;
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  z-index: 10;
}
.header__menu {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.header__menu-burger {
  border: 1px solid #567199;
  border-radius: 6px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__menu-burger .svg-icon {
  color: #567199;
  font-size: 1.125rem;
}

@media all and (min-width: 64em) {
  .header {
    padding: 2rem;
    height: 85px;
  }
  .header__menu {
    display: none;
  }
}
/**
 * ==================== @ FOOTER
 */
.footer {
  background-color: #fff;
  position: relative;
}
.footer-contact {
  position: relative;
  background-color: #e48990;
  margin-top: 4rem;
  overflow: hidden;
}
.footer-contact::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg id='comp-lq9nqtmv-top' preserveAspectRatio='none' data-bbox='0 91 1920 209' viewBox='0 91 1920 209' height='100%25' width='100%25' xmlns='http://www.w3.org/2000/svg' data-type='shape'%3E%3Cdefs%3E%3Cstyle%3E%23comp-lq9nqtmv-top %3E g %7B transform: scaleY(-1) translateY(-391px); %7D %23comp-lq9nqtmv-top %7B fill: %23FFFFFF; %7D%3C/style%3E%3C/defs%3E%3Cg%3E%3Cpath fill-rule='evenodd' d='M1920 291C1656.8 169.2 1323.3 91 960 91S262.5 169.2 0 291v9h1920v-9z M0,90 h1920 v210 h-1920 Z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  left: 50%;
  top: -1px;
  height: 200px;
  min-width: 1920px;
  transform: scale(1, -1) translateX(-50%);
  z-index: 4;
}
.footer-contact figure img {
  object-fit: cover;
  width: 100%;
  height: 400px;
  image-rendering: pixelated;
}
.footer-contact .block-contact__caption {
  background-color: #FBF6F6;
  border-radius: 50%;
  border: 4px solid #48aecb;
  font-size: 0.8125rem;
  height: 320px;
  width: 320px;
}

@media screen and (min-width: 64em) {
  .footer-contact {
    margin-top: 6rem;
  }
  .footer-contact figure img {
    height: 640px;
  }
  .footer-contact .block-contact__caption {
    font-size: 1rem;
    height: 480px;
    width: 480px;
  }
}
/**
 * ==================== @ MENU NAV
 */
.nav {
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
}
.nav__items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.nav__item {
  position: relative;
}
.nav__item:hover > .nav__subitems {
  display: block;
}
.nav__subitems {
  padding-left: 0.5rem;
  z-index: 2;
}
.nav .nav__subitem {
  padding: 0.5rem;
}
.nav .nav__subitem .nav__link {
  color: #48aecb !important;
  font-size: 0.9375rem;
}
.nav__link {
  color: #48aecb !important;
  cursor: pointer;
  display: block;
  font-size: 1.125rem;
  position: relative;
  text-transform: uppercase;
}
.nav__link::after {
  content: "";
  background-color: rgba(247, 167, 69, 0.3);
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 0;
  transition: width 300ms;
}
.nav__icon {
  margin-top: 10%;
}
.nav-footer {
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem 2rem;
  padding: 2rem 0;
}
.nav-footer__link {
  color: #567199;
  font-size: 0.9375rem;
  transition: color 300ms;
}
.nav-footer__link:hover {
  color: #F7A745;
}
.nav-footer__link:last-child {
  display: inline;
}
.nav-footer__link:last-child::before {
  content: url(/assets/images/layout/eco-24.png);
  margin-right: 6px;
  vertical-align: -4px;
}

@media all and (max-width: 1023px) {
  .nav--main {
    align-items: center;
    position: fixed;
    padding-top: 2rem;
    top: 64px;
    bottom: 0;
    right: -100%;
    width: 100%;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    transition: right 300ms;
  }
  .is-nav-open .nav--main {
    right: 0;
  }
}
@media all and (min-width: 64em) {
  .nav {
    flex-direction: row;
    justify-content: center;
  }
  .nav__items {
    flex-direction: row;
  }
  .nav__item:hover > .nav__subitems {
    display: block;
  }
  .nav__link:hover::after, .nav__link[aria-current=page]::after {
    width: 100%;
  }
  .nav__subitems {
    background-color: #fff;
    display: none;
    position: absolute;
    left: 0;
    right: 0;
  }
  .nav__icon {
    display: none;
  }
}
/**
 * ==================== @ BLOCS
*/
.block-hero {
  margin-top: -9rem;
}
.block-hero__metas {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}
.block-themas {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1rem;
}
.block-prices {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1rem;
}
.block-separator {
  padding-top: 0;
  padding-bottom: 100px;
}
.block-separator::after {
  content: "";
  background: #fff url(/assets/images/layout/flowers-mini.webp) no-repeat center;
  background-size: contain;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.2);
  margin-left: -50px;
  position: absolute;
  left: 50%;
  bottom: 36px;
  height: 100px;
  width: 100px;
  z-index: 2;
}
.block-contact figure {
  background-color: #000;
}
.block-contact figure img {
  object-fit: cover;
  width: 100%;
  height: 560px;
}
.block-contact__caption {
  background-color: #FBF6F6;
  border: 4px solid #48aecb;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 2.5rem;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.block-contact__caption p {
  font-size: 0.8125rem;
  margin-top: 0;
  margin-bottom: 0.25rem;
}
.block-contact__caption a:not(.btn) {
  text-decoration: none;
  color: inherit;
}
.block-slider {
  background-color: #f4f4f4;
  color: #fff;
  margin-top: 2rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.home .block-slider {
  background-color: #e48990;
  color: #fff;
  padding-bottom: 4rem;
}
.block-slider figure {
  overflow: inherit;
}

/* --- 1024 --- */
@media all and (min-width: 64em) {
  .block-hero {
    margin-top: -9rem;
  }
  .block-suptitle {
    font-size: 1rem;
  }
  .block-title {
    margin-top: 2rem;
    padding-bottom: 1rem;
  }
  .block-themas {
    padding: 2rem;
  }
  .block-separator {
    padding-top: 0;
    padding-bottom: 100px;
  }
  .block-separator::after {
    margin-left: -90px;
    bottom: 0px;
    height: 180px;
    width: 180px;
  }
  .block-prices {
    padding: 2rem;
  }
  .block-contact__caption p {
    font-size: 1.125rem;
  }
}
/**
 * ==================== @ ELEMENT CARD / TEASER
*/
.card {
  display: block;
  position: relative;
}
.card-thema {
  box-shadow: 0px 0px 24px -8px rgba(0, 0, 0, 0.7);
  flex: 1 0 100%;
}
.card-thema figure {
  border-radius: 4px;
  overflow: hidden;
}
.card-thema figure img {
  transition: transform 600ms;
}
.card-thema__title {
  background-color: #fff;
  border-radius: 16px;
  color: #F56F8E;
  font-size: 1.125rem;
  min-width: 90%;
  padding: 1.5rem 1rem 1.2rem 1rem;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
}
.card-thema:hover img {
  transform: scale3d(1.1, 1.1, 1);
}
.card-price {
  box-shadow: 0px 0px 24px -8px rgba(0, 0, 0, 0.7);
  flex: 1 0 100%;
}
.card-price figure {
  border-radius: 4px;
  overflow: hidden;
}
.card-price h3 {
  color: var(--theme-color);
  font-size: 1.5rem;
  margin-bottom: 0;
  text-align: center;
}
.card-price .entry {
  padding: 0.5rem 1rem;
  text-align: center;
}
.card-slide {
  padding: 0.5rem;
}
.card-slide img {
  box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.7);
}
.card-slide h3 {
  position: absolute;
  left: 0.5rem;
  bottom: 8px;
  z-index: 2;
  right: 0.5rem;
  text-align: center;
  font-size: 14px;
  margin: 0;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: 400;
}

/* --- 768 --- */
@media screen and (min-width: 48em) {
  .card-thema {
    flex: 1 0 45%;
    max-width: 50%;
  }
  .card-thema__title {
    font-size: 1.375rem;
  }
  .card-price {
    flex: 1 0 45%;
    max-width: 50%;
  }
}
/**
 * ==================== @ LAYOUT FORM
*/
.grid-form {
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;
}
.grid-form__field .form__input {
  width: 100%;
}

@media all and (min-width: 48em) {
  .grid-form {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem 2rem;
  }
  .grid-form__field.m--fullwidth {
    grid-column: 1/-1;
  }
}
/*
 * ==================== @ SWIPER CUSTOMIZE
 */
.swiper {
  --swiper-pagination-bottom: -5px;
  --swiper-pagination-color: #F7A745;
  --swiper-pagination-bullet-inactive-opacity: .4;
  --swiper-pagination-bullet-border-radius: 0;
  --swiper-pagination-bullet-width: 24px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-bullet-inactive-color: #C7B99D;
  --swiper-navigation-color: #F7A745;
  opacity: 0;
  width: 100%;
}
.swiper-initialized {
  opacity: 1;
}
.swiper-nav {
  display: none !important;
  background-color: #C7B99D;
  border-radius: 50% !important;
  height: 36px !important;
  width: 36px !important;
  top: auto !important;
  bottom: 0 !important;
  right: 0 !important;
  left: auto !important;
}
.swiper-nav::after {
  content: none !important;
}
.swiper-nav.swiper-button-prev {
  right: 40px !important;
}
.swiper-nav .svg-icon {
  color: #000;
  font-size: 0.75rem !important;
  height: 1rem;
}
.swiper-wrapper {
  padding-bottom: 1rem;
}

@media screen and (min-width: 40em) {
  .swiper {
    --swiper-pagination-bullet-width: 48px;
  }
}
/**
 * ==================== @ GENERIC / PAGES
 */
.page-header {
  margin-top: 64px;
  position: relative;
  overflow: hidden;
}
.page-header figure img {
  object-fit: cover;
  width: 100%;
  height: 420px;
  object-position: top;
}
.page-header::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg id='comp-lqpo28l6-bottom' preserveAspectRatio='none' data-bbox='0 91 1920 209' viewBox='0 91 1920 209' height='100%25' width='100%25' xmlns='http://www.w3.org/2000/svg' data-type='shape'%3E%3Cdefs%3E%3Cstyle%3E%23comp-lqpo28l6-bottom %3E g %7B transform: scaleY(-1) translateY(-391px); %7D %23comp-lqpo28l6-bottom %7B fill: %23FFFFFF; %7D%3C/style%3E%3C/defs%3E%3Cg%3E%3Cpath fill-rule='evenodd' d='M1920 291C1656.8 169.2 1323.3 91 960 91S262.5 169.2 0 291v9h1920v-9z M0,90 h1920 v210 h-1920 Z'/%3E%3C/g%3E%3C/svg%3E") no-repeat center bottom;
  height: auto;
  padding-top: 11%;
}
.page-header__title {
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
}
.page-header__caption {
  position: absolute;
  max-width: 1024px;
  margin: auto;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 10%);
  width: 100%;
  z-index: 4;
}

/**
 * ==================== @ HOME
 */
.home .page-header {
  height: calc(100vh - var(--header-height));
}
.home .page-header::after {
  content: none;
}
.home .page-header figure {
  position: fixed;
  left: 0;
  right: 0;
  top: var(--header-height);
}
.home .page-header figure img {
  object-fit: cover;
  width: 100%;
  height: calc(100vh - var(--header-height));
}
.home .page-header__title {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: max-content;
  z-index: 2;
}
.home .page-header__title .baseline {
  color: #fff;
  display: block;
  font-size: 1.5rem;
}
.home .page-header__title .author {
  color: #fff;
}
.home .layout-txt .entry h2 {
  text-align: center;
}
.home .layout-txt .entry p {
  text-align: justify;
}

.page-content {
  background-color: #fff;
  padding-bottom: 2rem;
}

/**
 * ==================== @ ALL PAGES
 */
/* === 1024px === */
@media screen and (min-width: 64em) {
  .page-header {
    margin-top: 85px;
  }
  .page-header__title {
    font-size: 5rem;
  }
}
/**
 * ==================== @ BLOCKS SECTION
 */
section {
  padding-top: 2rem;
  position: relative;
}
section.bg-rose {
  color: #fff;
  background-color: #F56F8E;
}
section.bg-saumon {
  color: #fff;
  background-color: #e48990;
}
.home section.layout-txt {
  padding-top: 6rem;
  padding-bottom: 8rem;
}
.home section.layout-txt::before, .home section.layout-txt::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
}
.home section.layout-txt::before {
  background: url(/assets/images/layout/petal-top.webp) no-repeat top right;
  background-size: contain;
  top: 0;
  right: 0;
  width: 280px;
  height: 150px;
}
.home section.layout-txt::after {
  background: url(/assets/images/layout/petal-bottom.webp) no-repeat bottom left;
  background-size: contain;
  bottom: 0;
  left: 0;
  width: 280px;
  height: 150px;
}
section.layout-2cols .data-first, section.layout-img-txt .data-first, section.layout-txt-img .data-first {
  padding-right: 2rem;
}
section.layout-2cols .data-last, section.layout-img-txt .data-last, section.layout-txt-img .data-last {
  padding-left: 2rem;
}
section.layout-2cols figure, section.layout-img-txt figure, section.layout-txt-img figure {
  padding: 0 2rem;
}

/* === 1024px === */
@media screen and (min-width: 64em) {
  .home section.layout-txt::before {
    width: 400px;
    height: 215px;
  }
  .home section.layout-txt::after {
    width: 400px;
    height: 215px;
  }
  section.layout-2cols .data-first, section.layout-img-txt .data-first, section.layout-txt-img .data-first {
    padding-right: 2rem;
  }
  section.layout-2cols .data-last, section.layout-img-txt .data-last, section.layout-txt-img .data-last {
    padding-left: 2rem;
  }
}
/**
 * ==================== @ Really ?!
 */
.is-semantic {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  display: block;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.is-hidden {
  display: none !important;
  overflow: hidden !important;
}