/**
 * ==================== @ LAYOUT FORM
*/

.grid-form {
	display: grid;
	grid-template-columns: auto;
  gap: 1rem;

  &__field {

    .form__input {
      width: 100%;
    }
  }
}

@media all and (min-width : $bp-m) {

  .grid-form {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem 2rem;

    &__field {
  
      &.m--fullwidth {
        grid-column: 1/-1;
      }
    }
  }
}
