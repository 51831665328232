/**
 * ==================== @ ELEMENT CARD / TEASER
*/

.card {
  display: block;
  position: relative;

  &-thema {
    box-shadow: 0px 0px 24px -8px rgba(0,0,0,0.7);
    flex: 1 0 100%;

    figure {
      border-radius: 4px;
      overflow: hidden;

      img {
        transition: transform 600ms;
      }
    }

    &__title {
      background-color: #fff;
      border-radius: 16px;
      color: $color3;
      font-size: rem(18);
      min-width: 90%;
      padding: 1.5rem 1rem 1.2rem 1rem;
      position: absolute;
      left: 50%;
      top: 50%;
      text-align: center;
      text-transform: uppercase;
      transform: translate(-50%,-50%);
    }

    &:hover {
      img {
        transform: scale3d(1.1,1.1,1);
      }
    }
  }

  &-price {
    box-shadow: 0px 0px 24px -8px rgba(0,0,0,0.7);
    flex: 1 0 100%;

    figure {
      border-radius: 4px;
      overflow: hidden;
    }

    h3 {
      color: var(--theme-color);
      font-size: rem(24);
      margin-bottom: 0;
      text-align: center;
    }

    .entry {
      padding: .5rem 1rem;
      text-align: center;
    }
  }

  &-slide {
    padding: .5rem;

    img {
      box-shadow: 0px 0px 12px -4px rgba(0,0,0,0.7);
    }

    h3 {
      position: absolute;
      left: .5rem;
      bottom: 8px;
      z-index: 2;
      right: .5rem;
      text-align: center;
      font-size: 14px;
      margin: 0;
      padding: .5rem;
      background-color: rgba(0,0,0,.2);
      font-weight: 400;
    }
  }
}

/* --- 768 --- */
@media screen and (min-width: $bp-m) {

  .card {

    &-thema {
      flex: 1 0 45%;
      max-width: 50%;

      &__title {
        font-size: rem(22);
      }
    }

    &-price {
      flex: 1 0 45%;
      max-width: 50%;
    }
  }
}
