/**
 * ==================== @ HEADER
 */

.header {
	background-color: #fff;
	padding: 1rem;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 64px;
	z-index: 10;

	&__menu {
		position: absolute;
		top: 1rem;
		right: 1rem;

		&-burger {
			border: 1px solid $color1;
			border-radius: 6px;
			height: 32px;
			width: 32px;
			display: flex;
			justify-content: center;
			align-items: center;

			.svg-icon {
				color: $color1;
				font-size: rem(18);
			}
		}
	}
}

@media all and (min-width : $bp-l) {
	
	.header {
		padding: 2rem;
		height: 85px;

		&__menu {
			display: none;
		}
	}
}
