/**
 * ==================== @ GENERIC FORMS
*/

.form {
	font-family: $font-stack;

	&__field {
		display: flex;
		position: relative;
		padding-bottom: .5rem;
	}

	&__label {
		display: inline-block;
		font-size: rem(17);
		font-weight: 300;
		padding-left: 16px;
		padding-bottom: 4px;
		
		&.m--mandatory {

			span {
				cursor: pointer;
				font-size: rem(11);
				font-weight: 300;
				line-height: .9;
			}
		}
	}

	&__input, &__text {
		border: 0;
		border-radius: 24px;
		color: #000;
		outline: 0;
		padding: 7px 16px;
		background-color: rgba($color5, .1);
		font-size: em(17);
		font-family: inherit;
		font-weight: 300;

		.m--submit-inline & {
			padding-right: 25%;
			width: 100%;
		}
	}

	&__text {
		min-height: 120px;
		padding: 8px 16px;
		width: 100%;
	}
	
	&__button {
		font-family: inherit;
		font-size: em(15);
		font-weight: 500;
		
		.m--submit-inline & {
			position: absolute;
			right: 0;
			z-index: 2;
		}
	}

	.f_error {
		color: red;
		padding-left: 1rem;
	}

	.f_alert {
		font-size: rem(18);
		text-align: center;
		margin: 4rem 0;
		
		&.success {
			color: green;
			padding: 1rem;

			&::before {
				content: "✓";
				height: 14px;
				width: 14px;
				margin-right: .5rem;
				display: inline-block;
			}
		}
	}
}
