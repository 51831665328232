/**
 * ==================== @ THEMES
*/

.them-therapie {
  --theme-color: #{$color7};
  --theme-color-alt: #df2970;
}

.them-coaching {
  --theme-color: #{$color8};
  --theme-color-alt: #af0707;
}

.them-usui {
  --theme-color: #{$color3};
  --theme-color-alt: #cd5c60;
}

.them-kuna {
  --theme-color: #{$color4};
  --theme-color-alt: #ffa428;
}

.them-about {
  --theme-color: #bc6b3d;
  --theme-color-alt: #bc6b3d;
}


