
h1, h2, h3, h4, h5 {
	line-height: 1.1;
	margin: .5rem 0 1rem 0;
}

h1 {
	color: #fff;
	font-size: rem(30);
	line-height: 1.1;
	margin: .5rem 0;
	
	&.m--404 {
		font-size: rem(44);
	}
}

h2 {
	font-size: rem(25);
	font-weight: 600;
	line-height: 1.2;
	position: relative;
	margin-bottom: 1rem;
}

h3 {
	font-size: rem(21);
	margin: 1.5rem 0;
}

h4 {
	font-size: rem(16);
}

.entry, .entry p, p {
	font-size: rem(16);
	line-height: 1.5;
	margin: .5rem 0 1rem 0;

	a:not(.btn) {
		font-size: rem(16);
		text-decoration: underline;

		&:hover {
			text-decoration: underline;
		}
	}

	code {
		color: var(--theme-color, #F56F8E);
		font-family: $font-stack;
		font-weight: 500;
		font-size: rem(20);
		line-height: 1.2;
		text-decoration: none;
	}
}

.suptitle {
	color: $color4;
	font-weight: 500;
	font-size: rem(36);
	margin: 1rem 0 3rem 0;
}

.osmelis {
	padding-bottom: 2rem;
  text-align: center;

	h1, h2, h3 {
		color: $color3;
		font-size: rem(64);
		font-weight: 700;
    line-height: 1;
		text-shadow: #ffffff 3px 3px 0px, rgba(0, 0, 0, 0.2) 6px 6px 0px;
		text-transform: uppercase;
		margin-bottom: 0;
	}

  h1 {
    font-size: rem(64);
    margin-bottom: .75rem;
  }

  h2 {
    font-size: rem(40);
  }

	h3 {
		font-size: rem(40);
		text-shadow: #fff 2px 2px 0,rgba(0,0,0,.2) 4px 4px 0;
		margin: .5rem 0;
	}

	small {
		color: #fff;
    display: block;
		font-size: rem(24);

    .home & {
      font-size: rem(20);
    }
	}
}

.author {
	font-size: rem(40);
	font-weight: 700;
}

a[href^="mailto:"] {
	word-break: break-all;
}

ul {

	.grid-item & {
		margin-left: 0;
		padding-left: 1rem;
	}
}

.table-cookies {
	margin: 1rem 0;

	td, th {
		border-bottom: 1px solid #eee;
		font-size: rem(15);
		padding: 6px;
		text-align: left;
	}

	th {
		font-weight: 500;
	}
}

@media all and (min-width : $bp-m) {

	h1 {
		font-size: rem(96);
	}

	h2 {
		font-size: rem(48);
    margin-bottom: 2rem;
	}

	h3 {
		font-size: rem(30);
	}

  .osmelis {
    text-align: right;
    margin-right: 2rem;

    .home & {
      text-align: center;
    }

    h1 {
      font-size: rem(112);
      margin-bottom: 1rem;
    }

    h2 {
      font-size: rem(64);
    }

    small {

      .home & {
        font-size: rem(36);
      }
    }
  }

	.entry, .entry p, p {
		font-size: rem(18);

		code {
			font-size: rem(26);
		}

    h2 code {
      font-size: rem(33);
    }
	}

	.suptitle {
		font-size: rem(64);
	}

	.author {
		font-size: rem(80);
	}
}