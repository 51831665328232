/**
 * ==================== @ PAGINATION - NEWS
*/

.pagination {
	margin: 2rem 0;
	width: 100%;
	
	.pagination-list {
		display: flex;
		width: max-content;
		margin: 0 auto;

		.page-items {
			margin: 0 .3rem;
			text-align: center;
			
			a {
				border-radius: 50%;
				color: $color1;
				font-weight: 300;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 44px;
				width: 44px;
				transition: border-color 150ms;
			}
			
			.svg-icon {
				vertical-align: -2px;
			}

			&.m--active a, &:hover a {
				border: 1px solid $color1;
			}
		}
	}
}