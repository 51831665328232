/**
 * ==================== @ MENU NAV
 */
.nav {
  background-color: rgba(255,255,255,.9);
  display: flex;
  flex-direction: column;

  &__items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__item {
    position: relative;

    &:hover {
      > .nav__subitems {
        display: block;
      }
    }
  }

  &__subitems {
    padding-left: .5rem;
    z-index: 2;
  }

  .nav__subitem {
    padding: .5rem;

    .nav__link {
      color: $color2 !important;
      font-size: rem(15);
    }
  }

  &__link {
    color: $color2 !important;
    cursor: pointer;
    display: block;
    font-size: rem(18);
    position: relative;
    text-transform: uppercase;

    &::after {
      content: '';
      background-color: rgba($color4, .3);
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 0;
      transition: width 300ms;
    }
  }

  &__icon {
    margin-top: 10%;
  }

  &-footer {
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem 2rem;
    padding: 2rem 0;

    &__link {
      color: $color1;
      font-size: rem(15);
      transition: color 300ms;

      &:hover {
        color: $color4;
      }

      &:last-child {
        display: inline;
        
        &::before {
          content: url(/assets/images/layout/eco-24.png);
          margin-right: 6px;
          vertical-align: -4px;
        }
      }
    }
  }
}

@media all and (max-width : 1023px) {
  .nav--main {
    align-items: center;
    position: fixed;
    padding-top: 2rem;
    top: 64px;
    bottom: 0;
    right: -100%;
    width: 100%;
    backdrop-filter: blur(10px);
    transition: right 300ms;

    .is-nav-open & {
      right: 0;
    }
  }
}

@media all and (min-width : $bp-l) {
  .nav {
    flex-direction: row;
    justify-content: center;

    &__items {
      flex-direction: row;
    }

    &__item {
  
      &:hover {
        > .nav__subitems {
          display: block;
        }
      }
    }

    &__link {

      &:hover, &[aria-current="page"] {
        &::after {
          width: 100%;
        }
      }
    }

    &__subitems {
      background-color: #fff;
      display: none;
      position: absolute;
      left: 0;
      right: 0;
    }

    &__icon {
      display: none;
    }
  }
}
