/**
 * ==================== @ GENERIC FLOW TOOLS
 */

.l-clearfix { @extend %clearfix; }

.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.l-untitle {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.l-center {
	text-align: center;
}

.l-right {
	text-align: right;
}

.py-0 {padding: 0 !important}
.py-2 {padding: 2rem 0}
.py-2-0 {padding-top: 2rem}
.py-3-0 {padding-top: 3rem}
.py-4-0 {padding-top: 4rem}
.my-2-0 {margin-top: 2rem}
.my-3-0 {margin-top: 3rem}
.my-6-0 {margin-top: 6rem}
.my-9-0 {margin-top: 9rem}

.my-0-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.img-block {
	display: block;
}

.divider {
	/*url("data:image/svg+xml,%3Csvg id='comp-lqpo28l6-bottom' preserveAspectRatio='none' data-bbox='0 91 1920 209' viewBox='0 91 1920 209' height='100%25' width='100%25' xmlns='http://www.w3.org/2000/svg' data-type='shape'%3E%3Cdefs%3E%3Cstyle%3E%23comp-lqpo28l6-bottom %3E g %7B transform: scaleY(-1) translateY(-391px); %7D %23comp-lqpo28l6-bottom %7B fill: %23FFFFFF; %7D%3C/style%3E%3C/defs%3E%3Cg%3E%3Cpath fill-rule='evenodd' d='M1920 291C1656.8 169.2 1323.3 91 960 91S262.5 169.2 0 291v9h1920v-9z M0,90 h1920 v210 h-1920 Z'/%3E%3C/g%3E%3C/svg%3E");*/
}

/* EMBED
----------------------------------*/
.l-embed {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16.9 */
  height: 0;
	overflow: hidden;

  // &.m--rXXX {} // Ratio modifier
}

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page {
	min-height: 400px;
}

.l-main {
	background-color: #fff;
	position: relative;
}

#contact-form {
	scroll-margin-top: 150px;	
}

@media screen and (max-width: 767px) {

	.l--desktop {
		display: none;
	}
}


@media screen and (min-width: $bp-l) {

  .l--mobile {
    display: none;
  }

	.padded-edges {
		margin-left: 1rem;
		margin-right: 1rem;
	}
}