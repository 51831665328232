/**
 * ==================== @ THEME VARIABLES
 */

// --- Breakpoints
$bp-s: 40em;    // 640px
$bp-m: 48em;    // 768px
$bp-l: 64em;    // 1024px
$bp-lx: 72em;    // 1152px
$bp-1280: 80em; // 1280px
$bp-xl: 100em;  // 1600px
$max-s: 639px;
$max-large: 1279px;

$col-width: 112px; // grid fractionnal > body 1344px

// --- Paths
$font-path: '/assets/fonts/';
$img-path: '/assets/images/';

// --- Fonts
$icons-fontname: 'icons';
$default-font-size: 16px;
$font-stack: 'OpenSans', Helvetica, Arial, sans-serif;
$font-alt: Helvetica, Arial, sans-serif;


$c-black: #000;
$c-red: #F70004;
$c-green: #4CB8AD;
$c-text: #37383D;

$color1: #567199; // main color 
$color2: #48aecb; // main color alt
$color3: #F56F8E; // usui
$color4: #F7A745; // kuna
$color5: #e48990;
$color6: #FBF6F6;
$color7: #F33493; // therapie
$color8: #AE0707; // coaching
$bg-input: #504A40;



$color11: #4C94B2; // mer
$color13: #C7B99D;
$color16: #F4F1EB;
$bg-sand: #F8F7F3;

:root {
	--gutter: 1rem;
	--margin-layout: 1rem;
  --col-width: 85px;
	--radius-6: 3px;
	--radius-8: 4px;
	--radius-16: 8px;
	--header-height: 64px;
  --theme-color: #bc6b3d;
  --theme-color-alt: #bc6b3d;
}

@media screen and (min-width:$bp-l) {
	:root {
		--radius-6: 6px;
		--radius-8: 8px;
		--radius-16: 1rem;
		--header-height: 84px;
	}
}

// --- SVG
/*
$svg-pen: '<svg viewBox="0 0 38 32"><path d="M1.69 1.7A5.61 5.61 0 0 1 5.07.04h.02a4.03 4.03 0 0 1 3.33 1.11l21 21c.1.1.17.22.2.34l2.27 8.53a.75.75 0 0 1-.72.97.74.74 0 0 1-.2 0l-8.52-2.3a.68.68 0 0 1-.34-.2L1.14 8.43a4.8 4.8 0 0 1 .54-6.73zm25.13 24.07a5.69 5.69 0 0 0 1.27-2.76v-.04L8.34 3.22A7.8 7.8 0 0 1 6.9 5.85v-.01zm2-.32a8.52 8.52 0 0 1-3.38 3.4l-.05.03 4.7 1.27zm-5.9 2.67a5.66 5.66 0 0 0 2.81-1.28L5.81 6.94a7.76 7.76 0 0 1-2.56 1.43l-.06.02zM2 7.1a6.58 6.58 0 0 0 5.07-5.04L7.08 2a2.6 2.6 0 0 0-1.86-.47h.01c-.98.11-1.84.56-2.48 1.23A3.41 3.41 0 0 0 2 7.1z"></path></svg>';
*/

