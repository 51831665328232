/**
 * ==================== @ FOOTER
 */

.footer {
  background-color: #fff;
  position: relative;

  &-contact {
    position: relative;
    background-color: $color5;
    margin-top: 4rem;
    overflow: hidden;

    &::before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg id='comp-lq9nqtmv-top' preserveAspectRatio='none' data-bbox='0 91 1920 209' viewBox='0 91 1920 209' height='100%25' width='100%25' xmlns='http://www.w3.org/2000/svg' data-type='shape'%3E%3Cdefs%3E%3Cstyle%3E%23comp-lq9nqtmv-top %3E g %7B transform: scaleY(-1) translateY(-391px); %7D %23comp-lq9nqtmv-top %7B fill: %23FFFFFF; %7D%3C/style%3E%3C/defs%3E%3Cg%3E%3Cpath fill-rule='evenodd' d='M1920 291C1656.8 169.2 1323.3 91 960 91S262.5 169.2 0 291v9h1920v-9z M0,90 h1920 v210 h-1920 Z'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      left: 50%;
      top: -1px;
      height: 200px;
      min-width: 1920px;
      transform: scale(1,-1) translateX(-50%);;
      z-index: 4;
    }

    figure {

      img {
        object-fit: cover;
        width: 100%;
        height: 400px;
        image-rendering: pixelated;
      }
    }

    .block-contact__caption {
      background-color: $color6;
      border-radius: 50%;
      border: 4px solid $color2;
      font-size: rem(13);
      height: 320px;
      width: 320px;
    }
  }
}

@media screen and (min-width: $bp-l) {

  .footer {
  
    &-contact {
      margin-top: 6rem;

      figure {
  
        img {
          height: 640px;
        }
      }

      .block-contact__caption {
        font-size: rem(16);
        height: 480px;
        width: 480px;
      }
    }
  }
}