/**
 * ==================== @ BLOCS
*/

.block {

  &-hero {
    margin-top: -9rem;

    &__metas {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: .5rem;
    }
  }

  &-themas {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 1rem;
  }

  &-prices {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 1rem;
  }

  &-separator {
    padding-top: 0;
    padding-bottom: 100px;

    &::after {
      content: '';
      background: #fff url(/assets/images/layout/flowers-mini.webp) no-repeat center;
      background-size: contain;
      box-shadow: 0 0 12px 0px rgba(0,0,0,.2);
      margin-left: -50px;
      position: absolute;
      left: 50%;
      bottom: 36px;
      height: 100px;
      width: 100px;
      z-index: 2;
    }
  }

  &-contact {

    figure {
      background-color: #000;

      img {
        object-fit: cover;
        width: 100%;
        height: 560px;
      }
    }

    &__caption {
      background-color: $color6;
      border: 4px solid $color2;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      padding: 1.5rem 2.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      text-align: center;
      transform: translate(-50%,-50%);

      p {
        font-size: rem(13);
        margin-top: 0;
        margin-bottom: .25rem;
      }

      a:not(.btn) {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  &-slider {
    background-color: #f4f4f4;
    color: #fff;
    margin-top: 2rem;
    padding-top: 3rem;
    padding-bottom: 2rem;

    .home & {
      background-color: $color5;
      color: #fff;
      padding-bottom: 4rem;
    }

    figure {
      overflow: inherit;
    }
  }
}

/* --- 1024 --- */
@media all and (min-width : $bp-l) {

  .block {

    &-hero {
      margin-top: -9rem;
    }

    &-suptitle {
      font-size: rem(16);
    }

    &-title {
      margin-top: 2rem;
      padding-bottom: 1rem;
    }

    &-themas {
      padding: 2rem;
    }

    &-separator {
      padding-top: 0;
      padding-bottom: 100px;
  
      &::after {
        margin-left: -90px;
        bottom: 0px;
        height: 180px;
        width: 180px;
      }
    }

    &-prices {
      padding: 2rem;
    }

    &-contact {

      &__caption {

        p {
          font-size: rem(18);
        }
      }
    }
  }
}
