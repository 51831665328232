/**
 * ==================== @ GENERIC / PAGES
 */

.page-header {
	margin-top: 64px;
	position: relative;
  overflow: hidden;

	figure {

		img {
			object-fit: cover;
			width: 100%;
			height: 420px;
			object-position: top;
		}
	}
	
	&::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("data:image/svg+xml,%3Csvg id='comp-lqpo28l6-bottom' preserveAspectRatio='none' data-bbox='0 91 1920 209' viewBox='0 91 1920 209' height='100%25' width='100%25' xmlns='http://www.w3.org/2000/svg' data-type='shape'%3E%3Cdefs%3E%3Cstyle%3E%23comp-lqpo28l6-bottom %3E g %7B transform: scaleY(-1) translateY(-391px); %7D %23comp-lqpo28l6-bottom %7B fill: %23FFFFFF; %7D%3C/style%3E%3C/defs%3E%3Cg%3E%3Cpath fill-rule='evenodd' d='M1920 291C1656.8 169.2 1323.3 91 960 91S262.5 169.2 0 291v9h1920v-9z M0,90 h1920 v210 h-1920 Z'/%3E%3C/g%3E%3C/svg%3E") no-repeat center bottom;
    height: auto;
    padding-top: 11%;
	}

	&__title {
		font-size: rem(32);
		text-transform: uppercase;
		text-align: center;
	}

	&__caption {
		position: absolute;
		max-width: 1024px;
		margin: auto;
		top: 10%;
		left: 50%;
		transform: translate(-50%,10%);
		width: 100%;
		z-index: 4;
	}
}

/**
 * ==================== @ HOME
 */

.home {

	.page-header {
		height: calc(100vh - var(--header-height));

		&::after {
			content: none;
		}

		figure {
			position: fixed;
			left: 0;
			right: 0;
			top: var(--header-height);

			img {
				object-fit: cover;
				width: 100%;
				height: calc(100vh - var(--header-height));
			}

		}

		&__title {
			position: absolute;
			top: 50%;
			left: 50%;
			text-align: center;
			transform: translate(-50%,-50%);
			width: max-content;
			z-index: 2;

			.baseline {
				color:#fff;
				display: block;
				font-size: rem(24);
			}

			.author {
				color:#fff;
			}
		}
	}

  .layout-txt .entry {

    h2 {
      text-align: center;
    }

    p {
      text-align: justify;
    }
  }
}

.page {

	&-content {
		background-color: #fff;
		padding-bottom: 2rem;
	}
}
/**
 * ==================== @ ALL PAGES
 */

/* === 1024px === */
@media screen and (min-width:$bp-l) {
	
	.page-header {
		margin-top: 85px;

    &__title {
      font-size: rem(80);
    }
	}
}
