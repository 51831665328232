/**
 * ==================== @ BASE STYLES
 */
@font-face {
    font-family: 'OpenSans';
    src: url(path('OpenSans-Regular.woff2', $font-path)) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url(path('OpenSans-Italic.woff2', $font-path)) format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url(path('OpenSans-Medium.woff2', $font-path)) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url(path('OpenSans-Semibold.woff2', $font-path)) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url(path('OpenSans-Bold.woff2', $font-path)) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

html {
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
	background-color: #fff;
	color: $color1;
  font: em($default-font-size, 16) $font-stack;
	letter-spacing: .5px;
	padding: 0;
	margin: 0;
  position: relative;

  &.is-nav-open {
		height: 100vh;
    overflow: hidden;
  }
}

a:not(.btn) {
  color: $color1;
  text-decoration: none;
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

strong {
	font-size: inherit;
  font-weight: 700;
	margin-left: 0;
}

figure {
	display: block;
	margin: 0;
	overflow: hidden;
	position: relative;
	
	img {
		display: block;
		width: 100%;
	}
	
	picture {
		display: block;
	}
	
	figcaption {
		font-size: em(12);
	}
}

iframe { 
	border: 0; 
}

img {
  height: auto;
  max-width: 100%;
  display: inline-block;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
textarea,
select { border-radius: 0; }

.sv-g [class*="sv-u"] {
	font-family: $font-stack;
	letter-spacing: .1px;
}

@media screen and (min-width: $bp-l) {

  body {
    &.is-nav-open {
      height: auto;
      overflow: visible;
  
      &::before {
        content: none;
      }
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  html {
    // scroll-behavior: auto;
  }
}
